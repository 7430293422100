import {
  type AppKey,
  type CloudflowCreationStatus,
  type CloudflowEntityModel,
  type CloudFlowNodeType,
  type CloudFlowProvider,
  type NodeConfigApiParametersBase,
  type NodeConfigConditionalParameters,
  type NodeStatus,
  type ObjectValues,
} from "@doitintl/cmp-models";
import { type ModelReference } from "@doitintl/models-firestore";

export type CloudflowLink = {
  title: string;
  url: string;
};

export type CloudflowKindText = {
  subtitle: string;
  title: string;
};

export enum CloudflowKind {
  fromScratch = "fromScratch",
  template = "template",
}

export type CloudflowTemplate = {
  id: string;
  name: string;
  description: string;
  integrations: Integration[];
  ref: ModelReference<CloudflowEntityModel>;
};

export type Cloud = Omit<AppKey, "internal">;
export type Integration = "slack" | "jira" | "email";

export type DeleteCloudflowInput = {
  id: string;
};

export type CreateCloudflowInput = {
  name: string;
  firstNodeId: string;
  description?: string;
  status?: CloudflowCreationStatus;
};

export type UpdateCloudflowInput = {
  name?: string;
  description?: string;
  status?: CloudflowCreationStatus;
};
export type TriggerCloudflowResponce = {
  flowId: string;
  customerId: string;
  message: string;
  triggeredBy: string;
};

export type TestNodeResponse = {
  message?: string;
  error?: string;
  status: number;
};

export type CloudflowDTO = {
  id: string;
  name: string;
  description: string;
  status: string;
  customer: string;
  createdBy: string;
  firstNode: string | null;
  createdAt: Date;
  updatedAt: Date;
  nextRun?: Date | null;
};

export type TestNodeRequest = {
  customerId: string;
  cloudflowId: string;
  nodeId: string;
  input?: Record<string, any>[] | unknown;
};

export type ConfigurationTab = "Parameters" | "Permissions" | "Test";

export type AWSNodeConfigApiParameters = NodeConfigApiParametersBase & {
  provider: CloudFlowProvider.AWS;
  configurationValues: { accountId: string; region: string };
};

export type GCPNodeConfigApiParameters = NodeConfigApiParametersBase & {
  provider: CloudFlowProvider.GCP;
  configurationValues: { organizationId: string; serviceAccount: string };
};

export type NodeConfigApiParameters = AWSNodeConfigApiParameters | GCPNodeConfigApiParameters;

export type NodeParameters<TNodeType extends CloudFlowNodeType> = TNodeType extends CloudFlowNodeType.ACTION
  ? NodeConfigApiParameters
  : TNodeType extends CloudFlowNodeType.CONDITION
    ? NodeConfigConditionalParameters
    : unknown;

export interface NodeConfigs<TNodeType extends CloudFlowNodeType = CloudFlowNodeType> {
  name: string;
  parameters: NodeParameters<TNodeType>;
  type: TNodeType;
  id: string;
  flowId: string;
  status: NodeStatus;
  statusMessage?: string;
}

export type CloudPermissions = {
  requiredPermissions: string[];
  updatePermissions: () => Promise<void>;
  command: string;
  loading: boolean;
  isValid: boolean;
};

export type AWSPermissions = {
  [serviceName: string]: string[];
};

export type ExtractAPIConfigurationValues<TProvider extends CloudFlowProvider> = Extract<
  NodeConfigApiParameters,
  { provider: TProvider }
>["configurationValues"];

export type PermissionMap = {
  [serviceName: string]: boolean;
};

export type Position = {
  x: number;
  y: number;
};

export type NodeData = NodeConfigs & {
  isActive: boolean;
  onEditNode: () => void;
  onDeleteNode: () => void;
  onAddNode: (nodeType: CloudFlowNodeType, id: string, position?: Position) => void;
  position?: Position;
};

export const EDGE_TYPE = {
  CUSTOM: "custom",
  GHOST: "ghost",
  CONDITION: "condition",
} as const;

export type EdgeType = ObjectValues<typeof EDGE_TYPE>;
