import { CloudFlowNodeType } from "@doitintl/cmp-models";

import { type NodeConfigs, type TestNodeResponse } from "../../types";

export const parseResponse = (response: string | { data: string | TestNodeResponse; status: number }) => {
  // when both worker and orchestrator are down, error returns as a string
  if (typeof response === "string" || !response) {
    return {
      isError: true,
      status: 500,
      responseMessage: response || "No response",
    };
  }

  if (!response.data || response.status >= 400) {
    if (typeof response.data === "string") {
      return {
        isError: true,
        status: response.status,
        responseMessage: response.data || "No response",
      };
    }
    return {
      isError: true,
      status: response.status,
      responseMessage: response.data?.error || response.data?.message || "No response",
    };
  }

  const {
    data: { message, error, status },
  } = response as { data: TestNodeResponse; status: number };

  if (response.status >= 400 || error !== undefined || message === undefined) {
    return {
      isError: true,
      status: response.status,
      responseMessage: error || message || "No response",
    };
  }

  try {
    const messageUnMarshal = JSON.parse(message);
    if (messageUnMarshal.error) {
      return {
        isError: true,
        status,
        responseMessage: messageUnMarshal.error,
      };
    }

    return {
      isError: false,
      status,
      responseMessage: messageUnMarshal.message,
    };
  } catch (e) {
    return {
      isError: false,
      status: response.status,
      responseMessage: message,
    };
  }
};

export const getTestResponse = async (
  nodeConfig: NodeConfigs,
  onTestNode: (nodeConfig: NodeConfigs) => Promise<TestNodeResponse>
) => {
  if (nodeConfig.type === CloudFlowNodeType.ACTION) {
    return onTestNode(nodeConfig);
  }
};

export const isArrayOfObjects = (variable: any): boolean => {
  if (!Array.isArray(variable)) {
    return false;
  }

  return variable.every((item) => typeof item === "object" && item !== null && !Array.isArray(item));
};
