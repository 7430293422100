import { useMemo } from "react";

import { CloudFlowNodeType } from "@doitintl/cmp-models";

import { type ConfigurationTab, type NodeConfigs } from "../../../types";

export const useTabs = (nodeConfig: NodeConfigs) =>
  useMemo(() => {
    const tabs: ConfigurationTab[] = [];
    if (nodeConfig.parameters) tabs.push("Parameters");
    if (nodeConfig.type === CloudFlowNodeType.ACTION) tabs.push("Permissions");
    // TODO: decide when to show the test tab
    if (nodeConfig.type === CloudFlowNodeType.ACTION) tabs.push("Test");
    return tabs;
  }, [nodeConfig]);
