import { useCallback, useMemo, useState } from "react";

import { Alert, Box, Button, Stack, Typography } from "@mui/material";

import { Loader } from "../../../../../Components/Loader";
import { httpStatusMap } from "../../../../../utils/common";
import { type TestNodeResponse } from "../../../types";
import { getTestResponse, parseResponse } from "../../utils/testUtils";
import { useNodeConfigurationContext } from "../NodeConfigurationContext";
import { type TestProps } from "../NodeConfigurationPanel";

const TestsTab = ({ onTestNode, testNodeLoading }: TestProps) => {
  const { nodeConfig } = useNodeConfigurationContext();
  const [response, setResponse] = useState<TestNodeResponse>();
  const [clickedButton, setClickedButton] = useState(false);
  const onClickTest = useCallback(() => {
    setClickedButton(true);
    getTestResponse(nodeConfig, onTestNode).then((res) => {
      setResponse(res);
    });
  }, [nodeConfig, onTestNode]);

  const parsed = useMemo(
    () =>
      response
        ? parseResponse({
            data: response,
            status: response.status,
          })
        : { isError: true, status: "Still loading...", responseMessage: "" },
    [response]
  );

  return (
    <Stack alignContent="center" p={2.5}>
      {response && (
        <Box>
          <Alert severity={parsed.isError ? "error" : "success"}>
            {parsed.isError ? "Test failed" : "Test successful"}
          </Alert>
          <Box mt={2} />
        </Box>
      )}
      <Typography variant="subtitle2">Test this step to ensure everything works as expected</Typography>
      <Box mt={1} />
      <Box>
        <Button variant="outlined" onClick={onClickTest}>
          Test step
        </Button>
      </Box>
      <Box mt={2} />
      {clickedButton && (
        <Box>
          <Typography variant="subtitle2">Test output</Typography>
          <Box mt={1} />
          <Loader loading={testNodeLoading}>
            <Box
              component="pre"
              p={2}
              sx={{
                wordWrap: "break-word",
                color: "text.primary",
                backgroundColor: "general.backgroundDark",
                m: 0,
                border: 0,
              }}
            >
              <Box>
                <strong>Status:</strong> {httpStatusMap[parsed.status] ?? parsed.status}
              </Box>
              <Box>
                <strong>Message:</strong> {parsed.responseMessage}
              </Box>
            </Box>
          </Loader>
        </Box>
      )}
    </Stack>
  );
};

export default TestsTab;
