import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Redirect, useParams } from "react-router-dom";

import { CloudAnalyticsModel, CurrencyCodes, Metadata } from "@doitintl/cmp-models";
import { getCollection, useDocumentDataOnce } from "@doitintl/models-firestore";
import OpenNewIcon from "@mui/icons-material/OpenInNewRounded";
import { Button, Link } from "@mui/material";
import { PremiumFeatureCard } from "../../Components/Trial/TrialPremiumFeatureCard";
import { useSnackbar } from "../../Components/SharedSnackbar/SharedSnackbar.context";
import { useAttributionGroups } from "../../Components/hooks/cloudAnalytics/attributionGroups/useAttributionGroups";
import { useDefaultReportsConfig } from "../../Components/hooks/cloudAnalytics/useDefaultReportsConfig";
import { useCustomMetrics } from "../../Components/hooks/cloudAnalytics/useCustomMetrics";
import useRouteMatchURL from "../../Components/hooks/useRouteMatchURL";
import { useAttributionsContext } from "../../Context/AttributionsContext";
import { useAuthContext } from "../../Context/AuthContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useQuery } from "../../utils/useQuery";
import { DashboardsContextProvider } from "../../Context/DashboardContext";
import { alertTexts } from "../../assets/texts";
import { CSPCustomerID } from "../../utils/common";
import { useAlerts } from "../../Components/hooks/cloudAnalytics/alerts/useAlerts";
import { useCloudAnalyticsContext } from "../../Context/AnalyticsContext";
import { CircularProgressLoader } from "../../Components/Loader";
import { useTier } from "../../Context/TierProvider";
import AnalyticsTabs from "./AnalyticsTabs";
import { CloudAnalyticsContextProvider } from "./CloudAnalyticsContext";
import ReportContextWrapper from "./ReportContextWrapper";
import { AttributionGroups } from "./attributionGroups/AttributionGroups";
import { Attribution } from "./attributions/Attribution";
import { useDeleteAttributionValidation } from "./attributions/DeleteAttributionValidation";
import { useDeleteAttributionGroupValidation } from "./attributionGroups/DeleteAttributionGroupValidation";
import { Metric } from "./metrics";
import ReportCreate from "./reports/ReportCreate";
import { getCloudFromAssets, getPremiumFeatureCardText, useCustomerIntegrations } from "./utilities";
import { getReportTemplatesCards } from "./templateLibrary/utils";
import { useReportsData } from "../../Components/hooks/cloudAnalytics/useReportsData";

const metadataForDoersOnly = new Set(["fixed:invoice_month"]);

const CloudAnalytics = ({ userRoles, assetsLoading, assets, user, pageId, presentationModeActive }) => {
  const { isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const routeMatchURL = useRouteMatchURL();
  const query = useQuery();
  const { reportId, attributionId, metricId, attributionGroupId, customerId: customerIdUrlParam } = useParams();
  const { customer, init: customerCtxInit, organizations } = useCustomerContext();
  const {
    filteredAttributions: filteredAttributionsByTier,
    attributions,
    attributionsLoading,
  } = useAttributionsContext();
  const sharedSnackbar = useSnackbar();
  const [reportRevertKey, setReportRevertKey] = useState(0);
  const [loadAnalyticsLabels, setLoadAnalyticsLabels] = useState(false);
  const [attributionGroups, attributionGroupLoading] = useAttributionGroups();
  const { getFeatureKey } = useTier();
  const {
    metadata: metadataSnapshots,
    loading: metadataLoading,
    hasMetadata,
    hasDataHub,
    fetchMetadata,
    fetchAttributionGroupsMetadata,
    transforms,
    currency,
    hasEKSData,
  } = useCloudAnalyticsContext();

  const { reports, reportTemplates, reportTemplateFavorites } = useReportsData();

  const filteredAttributions = useMemo(
    () =>
      filteredAttributionsByTier.filter((attr) => {
        const entitlementsKeys = attr.data.entitlements?.map((entitlement) => getFeatureKey(entitlement)) ?? [];
        const isEntitlementAttribution = attr.data.entitlements && attr.data.entitlements.length > 0;
        const isEKSAttribution = entitlementsKeys.includes("pdi:eks");
        return (hasEKSData && isEKSAttribution) || !isEntitlementAttribution;
      }),
    [filteredAttributionsByTier, getFeatureKey, hasEKSData]
  );

  const handleRevertReport = useCallback(() => setReportRevertKey((key) => key + 1), []);

  const showSharedSnackbar = useCallback(
    ({ message, variant = "success", autoHideDuration = 5000, action }) => {
      sharedSnackbar.onOpen({
        message,
        variant,
        autoHideDuration,
        withClose: !action,
        action,
      });
    },
    [sharedSnackbar]
  );

  const handleMissingPermission = useCallback(
    (message) => {
      showSharedSnackbar({
        message,
        variant: "error",
        autoHideDuration: 6000,
        action: (
          <Link href={`/customers/${customer.id}/iam/users`} color="inherit" variant="button" underline="always">
            FIX
          </Link>
        ),
      });
    },
    [showSharedSnackbar, customer]
  );

  const refreshAttributionGroupsMetadata = useCallback(fetchAttributionGroupsMetadata, [
    fetchAttributionGroupsMetadata,
  ]);

  useEffect(() => {
    fetchMetadata(loadAnalyticsLabels, true);
  }, [fetchMetadata, loadAnalyticsLabels]);

  const loadAnalyticsLabelsCallback = useCallback(() => {
    setLoadAnalyticsLabels(true);
  }, []);

  const [metrics, metricsLoading] = useCustomMetrics();

  const [alertList] = useAlerts();

  const [extendedMetrics, extendedMetricsLoading] = useDocumentDataOnce(
    getCollection(CloudAnalyticsModel).doc("configs").collection("cloudAnalyticsConfigs").doc("extended-metrics")
  );

  const [datahubMetrics, datahubMetricsLoading] = useDocumentDataOnce(
    getCollection(CloudAnalyticsModel).doc("metrics").collection("datahubMetrics").doc(customer.id)
  );

  const [reportTemplatesWithVersions, dispatchReportTemplates] = reportTemplates;

  const [filteredReportTemplateFavorites, handleFavoriteReportTemplateUpdate] = reportTemplateFavorites;

  const [reportsList, filteredReports, reportsLoading] = reports;

  const reportTemplateCards = useMemo(
    () => getReportTemplatesCards(reportTemplatesWithVersions, reportsList),
    [reportTemplatesWithVersions, reportsList]
  );

  const { customerIntegrations } = useCustomerIntegrations();

  const filteredExtendedMetrics = useMemo(() => {
    const cloud = getCloudFromAssets(customer.assets);
    const visibility = customer.id === CSPCustomerID ? "csp" : "customer";

    return extendedMetrics?.metrics?.filter((metric) => {
      if (!!metric.cloud && !!cloud && metric.cloud !== cloud && !customerIntegrations?.includes(metric.cloud)) {
        return false;
      }

      return metric.visibility === "all" || metric.visibility === visibility;
    });
  }, [customer.assets, customer.id, customerIntegrations, extendedMetrics?.metrics]);

  const { defaultConfig, handleSaveDefaultReportConfig, loading: defaultConfigLoading } = useDefaultReportsConfig();

  useEffect(() => {
    setContext((prev) => ({
      ...prev,
      hasMetadata,
    }));
  }, [hasMetadata]);

  useEffect(() => {
    setContext((prev) => ({
      ...prev,
      defaultReportConfig: defaultConfig,
      defaultConfigLoading,
    }));
  }, [defaultConfig, defaultConfigLoading]);

  useEffect(() => {
    setContext((prev) => ({
      ...prev,
      extendedMetrics: filteredExtendedMetrics ?? [],
      datahubMetrics: datahubMetrics?.metrics ?? [],
      hasDataHub,
    }));
  }, [filteredExtendedMetrics, hasDataHub, datahubMetrics?.metrics]);

  const excludeSelectMetadataIds = useMemo(() => {
    if (isDoitEmployee) {
      return new Set();
    }

    return metadataForDoersOnly;
  }, [isDoitEmployee]);

  const getTemplateIdByReportId = useCallback(
    (reportId) => reportTemplatesWithVersions.find((rt) => rt.template.activeReport?.id === reportId)?.template.id,
    [reportTemplatesWithVersions]
  );

  useEffect(() => {
    const filterAlerts = {
      [`${Metadata.FIXED}:project_ancestry_names`]: (md) => {
        const t = (md?.data?.values?.slice(1) ?? []).findIndex((v) => /[a-zA-Z]/.test(v));
        if (t === -1) {
          return {
            severity: "info",
            action: (
              <Button
                color="inherit"
                size="small"
                component={Link}
                href="https://help.doit.com/google-cloud/connect-google-cloud-service-account"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Learn more"
                startIcon={<OpenNewIcon />}
                style={{ whiteSpace: "nowrap" }}
              >
                {alertTexts.LEARN_MORE}
              </Button>
            ),
            message: <>Connect your Google Cloud organization to show folder names instead of folder numbers</>,
          };
        }
      },
    };

    setContext((prevContext) => ({
      ...prevContext,
      customer,
      filterAlerts,
      handleMissingPermission,
      showSharedSnackbar,
      transforms,
      userRoles,
      handleFavoriteReportTemplateUpdate,
      getTemplateIdByReportId,
    }));
  }, [
    customer,
    handleMissingPermission,
    showSharedSnackbar,
    transforms,
    userRoles,
    handleFavoriteReportTemplateUpdate,
    getTemplateIdByReportId,
  ]);

  const premiumFeatureCardText = useMemo(() => getPremiumFeatureCardText(pageId), [pageId]);

  const [DeleteAttributionValidation, handleDeleteValidationResponse] = useDeleteAttributionValidation({
    reports: filteredReports,
    attributions: filteredAttributions.filter((a) => !a.data.hidden),
    attributionGroups: attributionGroups.filter((ag) => !ag.data.hidden),
    alerts: alertList,
    metrics,
    organizations,
  });

  const [DeleteAttributionGroupValidation, handleDeleteAttributionGroupValidationResponse] =
    useDeleteAttributionGroupValidation({
      reports: filteredReports,
      attributions: filteredAttributions.filter((a) => !a.data.hidden),
      attributionGroups: attributionGroups.filter((ag) => !ag.data.hidden),
      alerts: alertList,
      metrics,
      organizations,
    });

  const [context, setContext] = useState({
    customer,
    filterAlerts: null,
    handleMissingPermission,
    showSharedSnackbar,
    transforms: null,
    userRoles,
    extendedMetrics: filteredExtendedMetrics,
    datahubMetrics: datahubMetrics?.metrics ?? [],
    defaultReportConfig: defaultConfig,
    defaultConfigLoading,
    handleSaveDefaultReportConfig,
    hasMetadata,
    attributions,
    handleDeleteValidationResponse,
    handleDeleteAttributionGroupValidationResponse,
    handleFavoriteReportTemplateUpdate,
    getTemplateIdByReportId,
    loadAnalyticsLabels: loadAnalyticsLabelsCallback,
  });

  const AttributionGroupsDetails = useCallback(
    (draft) => {
      if (
        assetsLoading ||
        metadataLoading ||
        attributionsLoading ||
        attributionGroupLoading ||
        customerIdUrlParam !== customer.id
      ) {
        return null;
      }

      const selectedAttributionGroup = attributionGroups.find((b) => b.ref.id === attributionGroupId);
      if (!selectedAttributionGroup && !draft) {
        return <Redirect to="." />;
      }

      return (
        <AttributionGroups
          attributions={filteredAttributions}
          selectedAttributionGroup={selectedAttributionGroup}
          refreshAttributionGroupsMetadata={refreshAttributionGroupsMetadata}
        />
      );
    },
    [
      assetsLoading,
      attributionGroupId,
      attributionGroupLoading,
      attributionGroups,
      attributionsLoading,
      customer.id,
      customerIdUrlParam,
      filteredAttributions,
      metadataLoading,
      refreshAttributionGroupsMetadata,
    ]
  );

  if (!customerCtxInit) {
    return <CircularProgressLoader />;
  }

  const ReportDetails = () => {
    if (
      reportsLoading ||
      assetsLoading ||
      metadataLoading ||
      attributionsLoading ||
      metricsLoading ||
      extendedMetricsLoading ||
      datahubMetricsLoading ||
      customerIdUrlParam !== customer.id
    ) {
      return <CircularProgressLoader />;
    }

    const report = reportsList.find((r) => r.snapshot.id === reportId);

    if (!report || !hasMetadata) {
      return <Redirect to="." />;
    }

    const reportTemplateId = query.get("templateId");
    let isFavoriteReportTemplate = false;

    if (reportTemplateId) {
      const reportTemplateWithVersion = reportTemplatesWithVersions.find((rt) => rt.template.id === reportTemplateId);
      if (!isDoitEmployee && reportTemplateWithVersion?.template?.activeReport?.id !== reportId) {
        return <Redirect to={`/customers/${customer.id}/analytics/report-templates`} />;
      }
      isFavoriteReportTemplate = filteredReportTemplateFavorites.includes(reportTemplateId);
    }

    const defaultCurrency =
      report.data.config?.currency || customer.settings?.currency || currency || CurrencyCodes.USD;

    return (
      <Fragment key={reportRevertKey}>
        <ReportContextWrapper
          report={report}
          calculatedMetrics={metrics}
          filteredExtendedMetrics={filteredExtendedMetrics}
          defaultCurrency={defaultCurrency}
          excludeSelectMetadataIds={excludeSelectMetadataIds}
          handleRevertReport={handleRevertReport}
          attributionGroups={attributionGroups}
          reportTemplateId={reportTemplateId}
          isFavoriteReportTemplate={isFavoriteReportTemplate}
          reportTemplateCards={reportTemplateCards}
          dispatchReportTemplates={dispatchReportTemplates}
          hasDataHub={hasDataHub}
          datahubMetrics={datahubMetrics?.metrics ?? []}
        />
      </Fragment>
    );
  };
  const AttributionsDetails = () => {
    if (assetsLoading || metadataLoading || attributionsLoading || customerIdUrlParam !== customer.id) {
      return null;
    }

    let attribution;

    if (pageId !== "analytics:attributions:create") {
      attribution = attributions.find((attr) => attr.ref.id === attributionId);
      if (!attribution || !hasMetadata) {
        return <Redirect to="." />;
      }
    }

    return (
      <Attribution
        attribution={attribution}
        excludeSelectMetadataIds={excludeSelectMetadataIds}
        metadataSnapshots={metadataSnapshots}
        currency={currency}
      />
    );
  };

  const MetricsDetails = () => {
    /* we need customerIdUrlParam !== customer.id because:
     * 1. a rerender would be triggered when the URL (with a new metricId) would be called (searching for a metrics from a different customer)
     * 2. metrics list (from the old customer) would try to find the metric with metricId (new URL)
     * 3. it will fail to find the new metric and would redirect us to the Metrics list.
     * 4. same logic applies for the other details pages
     */
    if (
      assetsLoading ||
      metadataLoading ||
      attributionsLoading ||
      metricsLoading ||
      customerIdUrlParam !== customer.id
    ) {
      return null;
    }

    const metric = metrics.find((m) => m.snapshot.id === metricId);

    if (!!metricId && !metric) {
      return <Redirect to="." />;
    }

    return (
      <Metric
        metric={metric}
        metadataSnapshots={metadataSnapshots}
        attributions={filteredAttributions}
        assets={assets}
        currency={currency}
      />
    );
  };

  if (pageId === "analytics") {
    return <Redirect to={`${routeMatchURL}/reports`} />;
  }

  return (
    <DashboardsContextProvider>
      <CloudAnalyticsContextProvider value={context}>
        {presentationModeActive && premiumFeatureCardText && (
          <PremiumFeatureCard
            title={premiumFeatureCardText.title}
            detail={premiumFeatureCardText.details}
            tryFor={premiumFeatureCardText.tryFor}
          />
        )}
        <AnalyticsTabs
          user={user}
          metadataLoading={metadataLoading}
          reports={filteredReports}
          reportsLoading={reportsLoading}
          attributionsLoading={attributionsLoading}
          attributions={filteredAttributions.filter((a) => !a.data.hidden)}
          attributionGroups={attributionGroups.filter((ag) => !ag.data.hidden)}
          alerts={alertList}
          metrics={metrics}
          pageId={pageId}
          extendedMetrics={filteredExtendedMetrics}
          reportTemplateCards={reportTemplateCards}
        />
        {pageId === "analytics:reports:create" && <ReportCreate />}
        {pageId === "analytics:reports:details" && ReportDetails()}
        {pageId === "analytics:attributions:create" && AttributionsDetails()}
        {pageId === "analytics:attributions:details" && AttributionsDetails()}
        {pageId === "analytics:metrics:create" && MetricsDetails()}
        {pageId === "analytics:metrics:details" && MetricsDetails()}
        {pageId === "analytics:attribution-groups:create" && AttributionGroupsDetails(true)}
        {pageId === "analytics:attribution-groups:edit" && AttributionGroupsDetails(false)}
        <DeleteAttributionValidation />
        <DeleteAttributionGroupValidation />
      </CloudAnalyticsContextProvider>
    </DashboardsContextProvider>
  );
};

export default CloudAnalytics;
